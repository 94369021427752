// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "@firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAm8c_sbATTEVR7K9o4_qtH7IaGn6eEsX8",
  authDomain: "ksweb-36b4a.firebaseapp.com",
  projectId: "ksweb-36b4a",
  storageBucket: "ksweb-36b4a.appspot.com",
  messagingSenderId: "87319041496",
  appId: "1:87319041496:web:e86aba4f74e20f4a03cead",
  measurementId: "G-MGHMCEF3WY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);

export const storage = getStorage(app);
