import React, { useCallback, useEffect, useState } from "react";
import "./css/index.css";
import {
  ListInterface,
  UserAgentType,
} from "../../interface/common/common.inerface";
import { Link, useLocation, useNavigate } from "react-router-dom";

type Props = { userAgent: UserAgentType; data: ListInterface[] };

const ProductDetail = ({ data, userAgent }: Props) => {
  const [colorValue, setColorValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { product, category } = location.state;

  const sameCategoryProducts = data.filter(
    (item) => item.category === category && item.name !== product.name
  );

  const handleProductClick = useCallback(
    (product: any) => {
      navigate(`/product/detail/${product.name}`, {
        state: { product, category },
      });
    },
    [navigate, category]
  );

  useEffect(() => {
    setColorValue(product.color || "");
  }, [product]);

  console.log(colorValue);
  return (
    <main className="ks-detail">
      <div className="ks-detail-main">
        <div className="detail">
          <div className="left">
            <img src={product.img} alt={product.name} />
          </div>
          <div className="right">
            <div className="title-wrapper">
              <div className="category scdream7">{product.category} </div>
              <div className="name">{product.name} </div>
              <div className="level">{product.level} </div>
              <div className="text">{product.text} </div>
            </div>

            <div className="feature">
              <div className="title scdream7">Features</div>
              <ul>
                {product.features.split(",").map((feature: any, index: any) => (
                  <li key={index}>
                    <span style={{ marginRight: "0.3em" }}>•</span>
                    {feature.replace(/"|,/g, "").trim()}
                  </li>
                ))}
              </ul>
            </div>

            <div className="chat">
              <div className="chat-wrapper">
                <div className="title">Available sizes</div>
                <div className="content title">{product.size}</div>
              </div>

              <div className="chat-wrapper">
                <div className="title">Available coatings</div>
                <div className="content title">{product.colorcoating}</div>
              </div>

              <div className="chat-wrapper">
                <div className="title">Available color coating</div>
                <div className="content title">{product.coating}</div>
              </div>
            </div>

            <div className="color">
              <div className="color-text">
                <div className="title scdream7">Color</div>
                <div className="text">{product.textcolor}</div>
              </div>

              <div className="color-wrapper">
                {product.color.map((color: any, index: any) => (
                  <div
                    key={index}
                    className="color-box"
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
            </div>
            <Link to="/contact">
              <button className="contact-button">제품 문의하기</button>
            </Link>
          </div>
        </div>

        <div className="order-product">
          <div className="wrapper">
            <div className="title">다른제품 살펴보기</div>
            <div className="product-map">
              {sameCategoryProducts.map((item) => (
                <button
                  key={item.name}
                  onClick={() => handleProductClick(item)}
                >
                  <div className="product">
                    <img src={item.img} alt="" />
                    <div className="name">{item.name}</div>
                    <div className="level">{item.level}</div>
                    <div className="text">{item.text}</div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductDetail;
