import React, { useCallback, useEffect, useState } from "react";
import {
  ListInterface,
  UserAgentType,
} from "../../interface/common/common.inerface";
import { useNavigate } from "react-router-dom";

type Props = {
  userAgent: UserAgentType;
  data: ListInterface[];
  category: string;
};

const ProductList = ({ data, category }: Props) => {
  const filteredProducts = data.filter(
    (product) => product.category === category
  );
  const hasProductsToShow = filteredProducts.length > 0;
  const navigate = useNavigate();
  const handleProductClick = useCallback(
    (product: any) => {
      navigate(`/product/detail/${product.name}`, {
        state: { product, category },
      });
    },
    [navigate, category]
  );

  return (
    <div className={`product-category ${!hasProductsToShow && "non-taget"}`}>
      <div className="category scdream7">{category}</div>

      <div className={`product-map`}>
        {hasProductsToShow &&
          filteredProducts.map((product) => (
            <button
              key={product.name}
              onClick={() => handleProductClick(product)}
            >
              <div className="product">
                <img src={product.img} alt="" />
                <div className="name">{product.name}</div>
                <div className="level">{product.level}</div>
                <div className="text">{product.text}</div>
              </div>
            </button>
          ))}
      </div>
    </div>
  );
};

export default ProductList;
