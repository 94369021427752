import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./page/Main/Main";
import { UserAgentType } from "./interface/common/common.inerface";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import About from "./page/About/About";
import ContactContainer from "./page/Contact/Containers/ContactContainer";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import CERTIFI from "./page/About/certifi";
import ProductContainer from "./page/Product/Containers/ProductContainer";
import ProductDetailContainer from "./page/ProductDetail/Containers/ProductDetailContainer";
import { useSelector } from "react-redux";
import { RootState } from "./reducer";

function App() {
  const [userAgent, setUserAgent] = useState<UserAgentType>("pc");
  useEffect(() => {
    function change(e: any) {
      const width = e.target.innerWidth;
      if (width < 1440 && width > 767 && userAgent !== "tablet") {
        setUserAgent("tablet");
      }
      if (width < 768 && userAgent !== "mb") {
        setUserAgent("mb");
      }
      if (width > 1439 && userAgent !== "pc") {
        setUserAgent("pc");
      }
    }
    window.addEventListener("resize", change);
    return () => {
      window.removeEventListener("resize", change);
    };
  }, [userAgent]);
  useEffect(() => {
    const size = window.innerWidth;
    if (size < 1440 && size > 767) {
      setUserAgent("tablet");
    }
    if (size < 768) {
      setUserAgent("mb");
    }
    if (size > 1439) {
      setUserAgent("pc");
    }
    return () => {};
  }, []);
  return (
    <BrowserRouter>
      <Header userAgent={userAgent} />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Main userAgent={userAgent} />} />
        <Route
          path="/product"
          element={<ProductContainer userAgent={userAgent} />}
        />
        <Route
          path="/product/detail/*"
          element={<ProductDetailContainer userAgent={userAgent} />}
        />
        <Route path="/history" element={<About userAgent={userAgent} />} />
        <Route path="/certifi" element={<CERTIFI  userAgent={userAgent} />} />
        <Route
          path="/contact"
          element={<ContactContainer userAgent={userAgent} />}
        />
      </Routes>
      <Footer userAgent={userAgent} />
    </BrowserRouter>
  );
}

export default App;
