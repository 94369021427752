import React, { useCallback, useEffect, useState } from "react";
import "./css/index.css";
import { Link, useLocation } from "react-router-dom";
import { UserAgentType } from "../../interface/common/common.inerface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducer";
import { LangChange } from "../../reducer/config";

type Props = {
  userAgent: UserAgentType;
};

type MenuItem = {
  title: string;
  link: string;
  subMenu?: MenuItem[];
};

const Header = ({ userAgent }: Props) => {
  const [HeaderState, setHeaderState] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState<boolean>(false);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
  const lang = useSelector<RootState, string>((state) => state.config.lang);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleLanguageChange = () => {
    const newLanguage = lang === 'kor' ? 'eng' : 'kor';

    dispatch(LangChange(newLanguage));
  }
  const handleMenuClick = (link: string, subMenu?: MenuItem[]) => {
    setActiveSubMenu(activeSubMenu === link ? null : link);
  };

  useEffect(() => {
    setActiveSubMenu(null);
    const root = document.getElementById("root");

    function scrollEvent(e: Event) {
      const target = e.target as HTMLElement;
      const top = target.scrollTop;

      if (top > 100) {
        setHeaderState("black");
      } else {
        setHeaderState("");
      }
    }

    root?.addEventListener("scroll", scrollEvent);

    return () => {
      root?.removeEventListener("scroll", scrollEvent);
    };
  }, [location.pathname]);

  useEffect(() => {
    setIsOpen(false);
    return () => { };
  }, [location]);


  const renderMobileSubMenu = (subMenu: MenuItem[], parentLink: string) => {
    return (
      <div
        className={`mb-sub-menu ${activeSubMenu === parentLink ? "open" : ""}`}
      >
        {subMenu.map(({ title, link }) => (
          <Link key={link} to={link} className={`mb-menu font`}>
            {title}
          </Link>
        ))}
      </div>
    );
  };
  return (
    <>
      <header className={`${HeaderState} ${userAgent}`} onMouseLeave={() => {
        setHover(false)
      }}>
        <div className={`wrapper ${isOpen ? "open" : ""}`}>
          <div className="navbar">
            <Link to="/" className="logo-link">
              <img src={"/assets/common/logo.svg"} alt="logo" />
            </Link>
            {userAgent !== "mb" &&(
              <>
                <div className="pc-nav-wrapper"

                >
                  {layout.map(({ title, link, subMenu }, idx) => (
                    <ul key={idx} className="nav-item">
                      <Link
                        to={link}
                        className={`pc-nav font 
                        `}
                        onMouseOver={() => {
                          if (idx) {
                            setHover(false)
                          } else {
                            setHover(true)
                          }
                        }}
                      >
                        <li >{title}</li>
                      </Link>

                      {/* sub menu */}
                      {hover && <ul className="sub-nav-wrapper" >
                        {subMenu?.map((item, idx) => (
                          <Link key={idx} to={item.link}>
                            {item.title}
                          </Link>
                        ))}
                      </ul>}

                    </ul>
                  ))}
                </div>
                <button className="flag-container" onClick={handleLanguageChange}>
                  <img src={lang === "kor" ? "/assets/common/kor.png" : "/assets/common/english.png"} alt="" />
                  {lang === 'kor' ? "KOR" : "ENG"}
                </button>
              </>
            ) }
          </div>

          {/* 햄버거 메뉴바 */}
          <div className="right">
            {userAgent === "mb" &&(
              <button
                className="menu-btn"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <img
                  src={`/assets/common/${isOpen
                      ? location.pathname === "/education"
                        ? "cancel"
                        : "cancel"
                      : location.pathname === "/education"
                        ? "menu-education"
                        : "menu"
                    }.svg`}
                  alt={isOpen ? "닫기" : "메뉴"}
                />
              </button>
            )}
          </div>
        </div>
      </header>

      {/* 투글open 일 때 안에 내용 */}
      {userAgent !== "pc" && (
        <div className={`mb-header ${isOpen && "open-header"}`}>
          {layout.map(({ link, title, subMenu }, idx) => {
            return (
              <div key={idx} className="nav-item">
                {link === "/history" ? (
                  <div
                    onClick={() => handleMenuClick(link)}
                    className={`mb-menu font`}
                  >
                    {title}
                  </div>
                ) : (
                  <Link
                    to={link}
                    className={`mb-menu font`}
                    onClick={() => handleMenuClick(link)}
                  >
                    {title}
                  </Link>
                )}
                {activeSubMenu === link &&
                  subMenu &&
                  renderMobileSubMenu(subMenu, link)}
              </div>
            );
          })}
          <button className="mb-flag" onClick={handleLanguageChange}>
            <img src={lang === "kor" ? "/assets/common/kor.png" : "/assets/common/english.png"} alt="" />
            {lang === 'kor' ? "KOR" : "ENG"}
          </button>
        </div>
      )}
    </>
  );
};

export default Header;

const layout = [
  {
    title: "ABOUT US",
    link: "/history",
    subMenu: [
      {
        title: "HISTORY",
        link: "/history",
      },
      {
        title: "CERTIFICATION",
        link: "/certifi",
      },
    ],
  },
  {
    title: "PRODUCTS",
    link: "/product",
  },
  {
    title: "CONTACT",
    link: "/contact",
  },
];
