import React, { useCallback, useReducer } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addDoc, collection } from "firebase/firestore";

import { ContactInitial } from "../../../lib/initial";
import { UserAgentType } from "../../../interface/common/common.inerface";
import { ContactReducer } from "../../../lib/reducer";
import { LoadingChange } from "../../../reducer/config";
import { db } from "../../../config/firebase";
import ContactUs from "../ContactUs";
import { RootState } from "../../../reducer";

type Props = {
  userAgent: UserAgentType;
};
const ContactContainer = ({ userAgent }: Props) => {
  const rxDispatch = useDispatch();
  const [state, dispatch] = useReducer(
    ContactReducer,
    undefined,
    ContactInitial
  );
  const lang = useSelector((state: RootState) => state.config.lang) as "kor" | "eng";
  const SaveContact = useCallback(async () => {
    rxDispatch(LoadingChange(true));
    try {
      const obj = {
        ...state,
        timestamp: Date.now(),
      };
      await addDoc(collection(db, "glove"), obj);
      alert("문의가 성공적으로 완료되었습니다.");
      dispatch({
        type: "reset",
        payload: "",
      });

      window.location.reload();
      rxDispatch(LoadingChange(false));
    } catch (error: any) {
      rxDispatch(LoadingChange(false));
      console.error("문의 저장 중 오류 발생:", error);
    }
  }, [state]);

  return (
    <ContactUs
      userAgent={userAgent}
      data={state}
      dispatch={dispatch}
      SaveContact={SaveContact}
      lang={lang}
    />
  );
};

export default ContactContainer;
