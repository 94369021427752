import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inerface";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";

type Props = { userAgent: UserAgentType };

const About = ({ userAgent }: Props) => {
  const lang = useSelector<RootState>((state)=>state.config.lang)
  return (
    <main className="ks-about">
      <div className="ks-history">
        <div className="wrapper">
          <div className="title gmarket">HISTORY</div>
          <div className="text">
            {lang === "kor" ? "기업연혁" : "KS gloves history"}
          </div>

          <div className="korea">
            <img src="/assets/about/korea.svg" alt="" />
          </div>

          <div className="about-content">
            <div className="about-history">
              <div className="left">
                <div className="title scdream3">
                  THE LEADING BRAND <br />
                  IN COMPLETE
                  <br /> <span className="scdream7">HAND PROTECTION</span>
                </div>

                <div className="text">
                  {lang === "kor" ? 
                  <>
                      KS글러브는 다양한 환경에 맞는 장갑 제품의 제공하며
                      <br />
                      품질과 각 환경에서의 사용성 증진을 핵심가치로
                      <br /> 연구개발 및 생산하고 있습니다.
                  </>:
                  <>
                      We provide a reliable products to all of our valuable<br /> customers 
                      with constant R&D Spirits.
                  </>
                }
                  
                </div>
                <div className="img-wrapper">
                  <img src="/assets/about/history1.png" alt="" />
                </div>
              </div>
             
              <div className="right">
                <img src="/assets/about/history.svg" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="about-img">
          <img
            src={`/assets/about/${userAgent}/about-img.png`}
            alt=""
            srcSet={`/assets/about/${userAgent}/about-img@2x.png 2x, assets/about/${userAgent}about-img@3x.png 3x`}
          />
        </div>
      </div>
    </main>
  );
};

export default About;
