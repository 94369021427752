import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  ListInterface,
  UserAgentType,
} from "../../../interface/common/common.inerface";

import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../config/firebase";
import ProductDetail from "../ProductDetail";
import { useLocation } from "react-router-dom";

type Props = {
  userAgent: UserAgentType;
};
const ProductDetailContainer = ({ userAgent }: Props) => {
  const [data, setData] = useState<ListInterface[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "glovelist"));
        const productData = querySnapshot.docs.map(
          (doc) => doc.data() as ListInterface
        );
        setData(productData);
      } catch (error) {
        console.error("에러다", error);
      }
    };

    fetchData();
  }, []);

  return data ? <ProductDetail userAgent={userAgent} data={data} /> : null;
};

export default ProductDetailContainer;
