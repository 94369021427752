import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inerface";

type Props = {
  userAgent: UserAgentType;
};

const Footer = ({ userAgent }: Props) => {
  return (
    <footer>
      <div className="footer-content-section">
        <div className="footer-content-left">
          <div className="logo-wrapper">
            <img src="/assets/footer/logo.svg" alt="" />
          </div>
          <div className="footer-content">
            <div className="footer-list">
              {layouts.map(({ img, content }, idx) => {
                return (
                  <div key={idx} className="list-content">
                    <div className="list-img">
                      <img src={`/assets/footer/${img}.svg`} alt={img} />
                    </div>
                    <div className="list-content">{content}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="copy-right">
            © 2023 KS GLOVES. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const layouts = [
  {
    img: "place",
    content: `32, Dae Jeon-gil Yeong Cheon-City Gyeong Buk, Korea`,
  },

  {
    img: "tel",
    content: "82-54-331-1256",
  },
  {
    img: "business",
    content: "82-54-331-1252",
  },
];
