import React, { useEffect, useState } from "react";
import "./css/index.css";
import {
  ListInterface,
  UserAgentType,
} from "../../interface/common/common.inerface";
import ProductList from "../../components/Product/ProductList";

type Props = {
  setData: React.Dispatch<React.SetStateAction<ListInterface[]>>;
  userAgent: UserAgentType;
  data: ListInterface[];
  OriginData: ListInterface[];
  lang? : 'kor' | 'eng';
};

const Product = ({ data, userAgent, OriginData, setData,lang }: Props) => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [nowCategory, setNowCategory] = useState<string | undefined >(undefined);
  const [SearchValue, setSearchValue] = useState<string | number>("");
  return (
    <main className="ks-product">
      <div className="ks-product-main">
        <div className="wrapper">
          <div className="title gmarket">PRODUCTS</div>
          <div className="text">{lang ==="kor" && "제품"}</div>

          <div className="korea">
            <img src="/assets/about/korea.svg" alt="" />
          </div>

          <div className="search-filter">
            <div className="drop-down-section">
              <div
                className={`drop-down-wrapper ${
                  filterOpen && "drop-down-open"
                }`}
              >
                <button
                  type="button"
                  className="drop-down-card"
                  onClick={() => {
                    setFilterOpen(!filterOpen);
                  }}
                >
                  {nowCategory  ? nowCategory : lang === 'kor' ? '전체보기' : 'All'}
                  <div className="filter-arrow">
                    <img src="/assets/common/filter.svg" alt="arrow" />
                  </div>
                </button>
                {SearchList.map((item, idx) => (
                  <button
                    type="button"
                    className="drop-down-card"
                    key={idx}
                    onClick={() => {
                      setNowCategory(item);
                      setFilterOpen(!filterOpen);
                    }}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>

            <input
              type="text"
              placeholder={lang === 'kor' ? '검색어 입력' : 'Search'}
              value={SearchValue}
              onChange={(e) => {
                const searchKeyword = e.target.value.toLowerCase();
                setSearchValue(searchKeyword);

                const filteredData = OriginData.filter((item) =>
                  item.name.toLowerCase().includes(searchKeyword)
                );
                setData(filteredData);
              }}
            />
            <img src="/assets/common/search.svg" alt="" />
          </div>

          {nowCategory === undefined ? (
            SearchList.map((item, idx) => {
              return (
                <div className="product-content" key={idx}>
                  <ProductList
                    data={data}
                    userAgent={userAgent}
                    category={item}
                  />
                </div>
              );
            })
          ) : (
            <div className="product-content">
              <ProductList
                data={data.filter((item) => item.category === nowCategory)}
                userAgent={userAgent}
                category={nowCategory as string}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Product;

const SearchList = [
  "CUT RESISTANT HAND GLOVES",
  "ESD CUT RESISTANT HAND GLOVES",
  "CUT RESISTANT CUFF LENGTH & SLEEVES",
  "COPPER GLOVES",
  "ESD CARBON",
  "Nylon Comfortable Hand Gloves",
  "NYLON GLOVES",
  "DOT GLOVES",
  "GREY GLOVES",
  "BLACK&BLUE GLOVES",
];
