import {
  ContactInterface,
  ListInterface,
} from "../interface/common/common.inerface";

export interface ContactInter extends ContactInterface {
  content: string;
  fileData: { name: string; url: string }[];
}

export interface ListInter extends ListInterface {
  content: string;
  fileData: { name: string; url: string }[];
}

export function ContactReducer(
  state: ContactInter,
  { payload, type, index }: { payload: any; type: string; index?: number }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "name":
      return { ...state, name: payload };
    case "nation":
      return { ...state, nation: payload };

    case "phone":
      return { ...state, phone: payload };
    case "email":
      return { ...state, email: payload };

    case "text":
      return { ...state, text: payload };

    case "reset":
      return {
        name: "",
        nation: "",
        phone: "",
        text: "",
        email: "",
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

export function ListReducer(
  state: ListInter,
  { payload, type, index }: { payload: any; type: string; index?: number }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "name":
      return { ...state, name: payload };

    case "category":
      return { ...state, category: payload };

    case "level":
      return { ...state, level: payload };

    case "text":
      return { ...state, text: payload };

    case "size":
      return { ...state, size: payload };
    case "coating":
      return { ...state, coating: payload };

    case "features":
      return { ...state, features: payload };

    case "color":
      return { ...state, color: payload };

    case "img":
      return { ...state, img: payload };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
