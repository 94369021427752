import React from "react";
import "./css/index.css";
import { useNavigate } from "react-router-dom";
import Slide from "../../components/Slide/Slide";
import { UserAgentType } from "../../interface/common/common.inerface";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";

type Props = { userAgent: UserAgentType };

const Main = ({ userAgent }: Props) => {
  const navigate = useNavigate();
  const lang = useSelector<RootState, string>((state) => state.config.lang);
  console.log("lang", lang);
  const Download = () => {
    let xhr = new XMLHttpRequest();
    let link = document.createElement("a");
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      var blob = URL.createObjectURL(xhr.response);
      link.href = blob;
      link.download = "KS GLOVE 소개서.pdf";
      link.click();
      link.remove();
      // [푸르지오아트홀] 좌석배치도
    };
    xhr.open("GET", "/file/intro.pdf");
    xhr.send();
  };
  return (
    <main className="ks-main">
      <div className="wrapper">
        <div className="section">
          <div className="back-img">
            <img
              src={`/assets/main/${userAgent}/back-img1.png`}
              srcSet={`/assets/main/${userAgent}/back-img1@2x.png 2x, assets/main/${userAgent}/back-img1@3x.png 3x`}
              alt=""
            />
          </div>

          <div className="section-title">
            <div className="title-wrapper">
              {userAgent === "mb" ? (
                <div className="title scdream3">
                  THE LEADING
                  <br />
                  BRAND IN
                  <br /> COMPLETE
                  <span className="scdream7">
                    HAND <br />
                    PROTECTION
                  </span>
                </div>
              ) : (
                <div className="title scdream3">
                  THE LEADING BRAND
                  <br /> IN COMPLETE
                  <br />
                  <span className="scdream7">HAND PROTECTION</span>
                </div>
              )}
              {userAgent === "mb" ? (
                <div className="text">
                  {lang === "kor" ? (
                    <>
                      {" "}
                      KS글러브는 다양한 환경에 맞는 장갑 <br /> 제품의 제공하며
                      품질과 각 환경에서의 <br />
                      사용성 증진을 핵심가치로 연구개발
                      <br /> 및 생산하고 있습니다.
                    </>
                  ) : (
                    <>
                      To comply with the current demands of
                      <br /> "Changes & evolving" on all kinds of Industries,
                      <br /> Especially, For the personal safety and
                      circumstances,
                      <br />
                      We, KS GLOVE CO.,LTD, have continuously improved
                      <br /> and exported our quality products/gloves with
                      strong
                      <br /> attention and passion to the all over the world
                      <br /> for over 40 year since 1981, will be continued
                      <br /> with the spirit of the "Challenge" and "R&D" for
                      the future.
                    </>
                  )}
                </div>
              ) : (
                <div className="text">
                  {lang === "kor" ? (
                    <>
                      {" "}
                      KS글러브는 다양한 환경에 맞는 장갑 제품의 제공하며 <br />
                      품질과 각 환경에서의 사용성 증진을 핵심가치로 <br />
                    </>
                  ) : (
                    <>
                      To comply with the current demands of "Changes & evolving"
                      <br />
                      on all kinds of Industries, Especially, For the personal
                      safety and circumstances,
                      <br />
                      We, KS GLOVE CO.,LTD, have continuously improved and
                      exported our quality products/gloves
                      <br />
                      with strong attention and passion to the all over the
                      world for over 40 year since 1981,
                      <br />
                      will be continued with the spirit of the "Challenge" and
                      "R&D" for the future.
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="section2">
          <div className="back-img">
            <div className="video-section">
              <video autoPlay loop muted playsInline preload="auto">
                <source src="/video/ks.webm" type="video/webm" />
                <source src="/video/ks.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="back"></div>
            <div className="section2-title">
              <div className="title">
                {lang === "kor" ? (
                  <>
                    꾸준한 연구와 투자를 담은 <br />
                    <span> 믿을 수 있는 제품을 제공합니다</span>
                  </>
                ) : (
                  <>
                    We provide a reliable products to all of <br />
                    our valuable customers with constant R&D Spirits.
                  </>
                )}
              </div>
              {userAgent === "mb" ? (
                <div className="text">
                  {lang === "kor" ? (
                    <>
                      안녕하십니까, KS글러브 대표 조두호입니다. <br />
                      저희 케이에스글러브는 1981년 경신사로 <br />
                      처음 문을 연 이래 지금까지 꾸준히 산업용 장갑을 <br />{" "}
                      만들어내고 있는 내실있는 기업입니다. <br /> 우수한 품질과
                      안전성을 목표로 꾸준한 연구개발과 <br />
                      시설투자를 통해 현재 동남아와 유럽, 미국 등 약 30개국에{" "}
                      <br /> 장갑을 수출하고 있습니다. 원예농업에서부터 <br />{" "}
                      항공산업분야에 이르기까지 산업장갑 분야의 <br />
                      대표 기업으로의 책임감을 다하겠습니다.
                    </>
                  ) : (
                    <>
                      Hello? This is a managing Dir(DOO HO, CHO) in KS GLOVE
                      CO.,LTD. Since 1981 as set up Kyungshin sa, We(KS GLOVE)
                      have continuously developed and produced the industrial
                      safety gloves, exported them to all over the world for
                      over 35years based on the premier quality and safety. It
                      is our responsibility to protect the workers’ hand from
                      Farm land to Aero space industries now. We will do our
                      best to supply the best and stable quality faithfully and
                      lastingly. Thank you for your kind interests and
                      cooperation. Yours sincerely. DOO HO,CHO/ Managing Dir. KS
                      GLOVE CO.,LTD.
                    </>
                  )}
                </div>
              ) : (
                <div className="text">
                  {lang === "kor" ? (
                    <>
                      안녕하십니까, KS글러브 대표 조두호입니다. <br />
                      저희 케이에스글러브는 1981년 경신사로 처음 문을 연 이래{" "}
                      <br />
                      지금까지 꾸준히 산업용 장갑을 만들어내고 있는 내실있는
                      기업입니다. <br />
                      우수한 품질과 안전성을 목표로 꾸준한 연구개발과 시설투자를
                      통해 <br />
                      현재 동남아와 유럽, 미국 등 약 30개국에 장갑을 수출하고
                      있습니다. <br />
                      원예농업에서부터 항공산업분야에 이르기까지 <br />
                      산업장갑 분야의 대표 기업으로의 책임감을 다하겠습니다.
                    </>
                  ) : (
                    <>
                      Hello? This is a managing Dir(DOO HO, CHO) in KS GLOVE
                      CO.,LTD. <br />
                      Since 1981 as set up Kyungshin sa, We(KS GLOVE) have
                      continuously developed and produced the <br />
                      industrial safety gloves, exported them to all over the
                      world for over 35years based on the premier <br />
                      quality and safety.It is our responsibility to protect the
                      workers’ hand from Farm land to Aero space <br />
                      industries now. We will do our best to supply the best and
                      stable quality faithfully and lastingly.
                      <br />
                      Thank you for your kind interests and cooperation. Yours
                      sincerely. DOO HO,CHO/ Managing Dir. KS GLOVE CO.,LTD.
                    </>
                  )}
                </div>
              )}

              <div className="name">
                {lang === "kor"
                  ? "KS글러브 대표 조두호"
                  : "Yours sincerely.DOO HO,CHO/ Managing Dir.KS GLOVE CO.,LTD."}
              </div>
            </div>
          </div>
        </div>

        <div className="section3">
          <div className="section3-title">
            <div className="step gmarket">STEP</div>
            <div className="title">
              {lang === "kor"
                ? "체계적인 공정으로 만들어지는"
                : "systematic process"}
              <br /> <span> KS GLOVE</span>
            </div>
            <div className="text">
              {lang === "kor" ? (
                <>
                  KS글러브는 체계적인 공정으로{userAgent === "mb" && <br />}{" "}
                  안전하고 튼튼한 장갑을 생산하고있습니다.
                </>
              ) : (
                "The production of products is well going on as systematic process which makes them a lot stable quality."
              )}
            </div>
          </div>

          <Slide userAgent={userAgent} />
        </div>

        <div className="fix-button">
          <button
            className="button-content"
            onClick={() => {
              navigate("/contact");
            }}
          >
            <img src="/assets/common/contact.svg" alt="" />
            {lang === "kor" ? "문의하기" : "Contact"}
          </button>

          <button className="button-content bottom" onClick={Download}>
            <img src="/assets/common/download.svg" alt="" />
            {lang === "kor" ? "소개서 다운" : "E-catalogue"}
          </button>
        </div>
      </div>
    </main>
  );
};

export default Main;
