import React, { useCallback, useState } from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inerface";
type Props = { userAgent: UserAgentType };
const Slide = ({ userAgent }: Props) => {
  let slideWidth = 902;

  if (userAgent === "tablet") {
    slideWidth = 740;
  }

  if (userAgent === "mb") {
    slideWidth = 370;
  }
  const maxTranslateX = -(section3.length - 1) * slideWidth;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = section3.length;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupImage, setPopupImage] = useState<{
    img: string;
    title: string;
    src: string;
  } | null>(null);

  let itemsPerPage = 2;

  if (userAgent === "mb") {
    itemsPerPage = 1;
  }
  const totalPages = Math.ceil(totalImages / itemsPerPage);

  const handlePageClick = useCallback(
    (page: number) => {
      const startIdx = (page - 1) * itemsPerPage;
      setPopupImage(section3[startIdx]);
      setCurrentImageIndex(startIdx);
    },
    [itemsPerPage]
  );

  const handlePreviewClick = useCallback((index: any) => {
    setPopupImage(section3[index]);
    setIsPopupOpen(true);
  }, []);

  const closePopup = useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  const nextSlide = useCallback(() => {
    if (translateX > maxTranslateX) {
      setTranslateX(translateX - slideWidth);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      setTranslateX(0);
      setCurrentIndex(0);
    }
  }, [translateX, maxTranslateX, slideWidth]);
  const prevSlide = useCallback(() => {
    if (translateX < 0) {
      setTranslateX(translateX + slideWidth);
      setCurrentIndex((prevIndex) => prevIndex - 1);
    } else {
      setTranslateX(maxTranslateX);
      setCurrentIndex(section3.length - 1);
    }
  }, [translateX, maxTranslateX, slideWidth, section3.length]);

  const handleNextClick = useCallback(() => {
    const nextImageIndex = (currentImageIndex + 1) % totalImages;
    setPopupImage(section3[nextImageIndex]);
    setCurrentImageIndex(nextImageIndex);
  }, [currentImageIndex, totalImages]);

  const handlePrevClick = useCallback(() => {
    const prevImageIndex = (currentImageIndex - 1 + totalImages) % totalImages;
    setPopupImage(section3[prevImageIndex]);
    setCurrentImageIndex(prevImageIndex);
  }, [currentImageIndex, totalImages]);

  const calculateActivePage = useCallback(() => {
    const currentPage = Math.floor(-translateX / slideWidth);
    return currentPage;
  }, [translateX, slideWidth]);

  return (
    <section className="ks-silde">
      <div className="preview-container">
        <button
          className={`arrow-left ${currentIndex === 0 && "hidden"}`}
          onClick={prevSlide}
          disabled={currentIndex === 0}
        >
          <img src="/assets/common/leftarrow2.svg" alt="" />
        </button>

        <div
          className="container-wrapper"
          style={{ transform: `translateX(${translateX}px)` }}
        >
          {" "}
          {section3.map((image, index) => (
            <button
              key={index}
              className={`preview-image ${
                currentImageIndex === index ? "active" : ""
              }`}
              onClick={() => handlePreviewClick(index)}
            >
              <img
                src={image.img}
                alt={`Preview ${index}`}
                srcSet={image.src}
              />
              <div className="name">{image.title}</div>
            </button>
          ))}
        </div>

        <button
          className={`arrow-right ${
            currentIndex === section3.length - 3 && "hidden"
          }`}
          onClick={nextSlide}
          disabled={
            userAgent === "mb"
              ? currentIndex === section3.length - 1
              : currentIndex === section3.length - 3
          }
        >
          <img src="/assets/common/rightarrow2.svg" alt="" />
        </button>
      </div>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`page-button ${
              calculateActivePage() === index ? "active" : ""
            }`}
            onClick={() => handlePageClick(index + 1)}
          ></button>
        ))}
      </div>

      {isPopupOpen && popupImage && (
        <div className="popup">
          <div className="popup-wrapper">
            <div className="popup-title">{popupImage.title}</div>
            <button className="close-button" onClick={closePopup}>
              <img src="/assets/common/cancel.svg" alt="" />
            </button>
          </div>

          <div className="popup-content">
            <img src={popupImage.img} alt={`Popup`} srcSet={popupImage.src} />
          </div>
          <div className="other-images">
            {section3.map((image, index) => {
              const isSameImage = image.img === popupImage.img;

              return (
                <div
                  key={index}
                  className={`other-image ${isSameImage ? "with-border" : ""}`}
                >
                  <img
                    src={image.img}
                    alt={`Other ${index}`}
                    srcSet={image.src}
                  />
                </div>
              );
            })}
          </div>

          <div className="button-wrapper">
            <button className="popup-arrow left" onClick={handlePrevClick}>
              <img src="/assets/common/left-arrow.svg" alt="" />
            </button>
            <button className="popup-arrow right" onClick={handleNextClick}>
              <img src="/assets/common/right-arrow.svg" alt="" />
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Slide;

const section3 = [
  {
    img: "/assets/main/section3-img1.png",
    title: "COVERING MACHINE",
    src: "/assets/main/section3-img1@2x.png 2x, assets/main/section3-img1@3x.png 3x",
  },

  {
    img: "/assets/main/section3-img2.png",
    title: "KNITTING MACHINE",
    src: "/assets/main/section3-img2@2x.png 2x, assets/main/section3-img2@3x.png 3x",
  },

  {
    img: "/assets/main/section3-img3.png",
    title: "COATING MACHINE",
    src: "/assets/main/section3-img3@2x.png 2x, assets/main/section3-img3@3x.png 3x",
  },

  {
    img: "/assets/main/section3-img4.png",
    title: "PRINTING MACHINE",
    src: "/assets/main/section3-img4@2x.png 2x, assets/main/section3-img4@3x.png 3x",
  },
];
