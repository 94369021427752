export const ContactInitial = () => {
  return {
    name: "",
    nation: "",
    phone: "",
    text: "",
    email: "",
  };
};

export const BookListInitial = () => {
  return {
    name: "",
    category: "",
    number: "",
    text: "",
    view: "",
    youtubeLink: "",
  };
};
