import React, { useCallback, useState } from "react";
import "./css/index.css";

import InfoInput from "../../components/InfoInput/InfoInput";
import { UserAgentType } from "../../interface/common/common.inerface";
import { ContactInter } from "../../lib/reducer";

type Props = {
  userAgent: UserAgentType;
  data: ContactInter;
  dispatch: React.Dispatch<{
    payload: any;
    type: string;
    index?: number;
  }>;
  lang : 'kor' | 'eng';
  SaveContact: () => void;
};

const ContactUs = ({ data, SaveContact, dispatch, userAgent ,lang}: Props) => {
  const CommonUpdate = useCallback(
    (type: string, value: any, index?: number) => {
      dispatch({
        type,
        payload: value,
        index,
      });
    },
    [dispatch]
  );
  const langChange = lang === "kor";
  return (
    <main className="question">
      <div className="contact-back">
        <div className="title gmarket">CONTACT US</div>
{langChange &&         <div className="text "> 고객문의</div>}
      </div>
      <div className={`question-wrapper`}>
        <div className="contact-title gmarket">CONTACT US</div>
        <div className="title-wrapper">
          {userAgent === "mb" ? (
            <div className="title">
              {langChange ? 
                <>
                  문의를 남겨주시면 담당자가 <br />
                  확인 후 연락드리겠습니다.
                </>:
                <>
                  If you have any questions, please contact our service board.
                </>
              }
              
            </div>
          ) : (
            <div className="title">
                {langChange ?
                  <>
                    문의를 남겨주시면 담당자가 <br />
                    확인 후 연락드리겠습니다.
                  </> :
                  <>
                    If you have any questions, <br />
                    please contact our service board.
                  </>
                }
            </div>
          )}
        </div>

        <div className="input-wrapper">
          <div className="left">
            <InfoInput
              title={langChange ? '국가' : 'Nation'}
              data={data.nation}
              catagory="nation"
              type="input"
              Update={CommonUpdate}
              placeholder={langChange ? '국가' : 'Nation'}
            />

            <InfoInput
              title={langChange ? '이름' : 'Name'}
              data={data.name}
              catagory="name"
              type="input"
              Update={CommonUpdate}
              placeholder={langChange ? '이름' : 'Name'}
            />
          </div>

          <div className="right">
            <InfoInput
              title={langChange ? '연락처' : 'Phone-number'}
              data={data.phone}
              catagory="phone"
              type="input"
              Update={CommonUpdate}
              placeholder={langChange ? '연락처' : 'Phone-number'}
            />

            <InfoInput
              title={langChange ? '이메일' : 'E-mail'}
              data={data.email}
              catagory="email"
              type="input"
              Update={CommonUpdate}
              placeholder={langChange ? '이메일' : 'E-mail'}
            />
          </div>
        </div>

        <InfoInput
          title={langChange ? '문의내용' : 'Memo'}
          data={data.text}
          catagory="text"
          type="text"
          Update={CommonUpdate}
          placeholder={langChange ? '문의내용' : 'Memo'}
        />

        <div className="button-send">
          <button
            className={`btn is-check-btn`}
            onClick={() => {
              console.log("클릭");
              SaveContact();
            }}
          >
            {langChange ? '문의하기' : 'Contact'}
          </button>
        </div>

        <div className="footer-content">
          <div className="footer-list">
            {layouts.map(({ img, content }, idx) => {
              return (
                <div key={idx} className="list-content">
                  <div className="list-img">
                    <img src={`/assets/contact/${img}.svg`} alt={img} />
                  </div>
                  <span >{content}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContactUs;

const layouts = [
  {
    img: "place",
    content: `32, Dae Jeon-gil Yeong Cheon-City Gyeong Buk, Korea`,
  },

  {
    img: "tel",
    content: "82-54-331-1256",
  },
  {
    img: "business",
    content: "82-54-331-1252",
  },
];
