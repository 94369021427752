import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  ListInterface,
  UserAgentType,
} from "../../../interface/common/common.inerface";

import Product from "../Product";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";

type Props = {
  userAgent: UserAgentType;
};
const ProductContainer = ({ userAgent }: Props) => {
  const [data, setData] = useState<ListInterface[]>([]);
  const [OriginData, setOriginData] = useState<ListInterface[]>([]);
  const [ListSize, setListSize] = useState<number>(0);
  const lang = useSelector((state: RootState) => state.config.lang) as "kor" | "eng";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "glovelist"));
        const data: ListInterface[] = [];

        querySnapshot.forEach((doc) => {
          const productData = doc.data() as ListInterface;
          data.push(productData);
        });
        setData(data);
        setOriginData(data);
      } catch (error) {
        console.error("에러다", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Product
      userAgent={userAgent}
      data={data}
      OriginData={OriginData}
      setData={setData}
      lang={lang}
    />
  );
};

export default ProductContainer;
