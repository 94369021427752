import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inerface";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";

type Props = {
  userAgent: UserAgentType;
};

const CERTIFI = ({ userAgent }: Props) => {
  const lang = useSelector<RootState>((state) => state.config.lang);

  return (
    <main className="ks-about certifi">
      <div className="ks-history">
        <div className="wrapper">
          <div className="title gmarket">CERTIFICATION</div>
          <div className="text">{lang === "kor" && "인증 및 특허"}</div>

          <div className="korea">
            <img src="/assets/about/korea.svg" alt="" />
          </div>

          <div className="title-wrapper">
            <div className="category gmarket">CERTIFICATION</div>
            <div className="text">
              {lang === "kor" ? (
                <>
                  믿고 사용할 수 있는 <br />
                  <span>전문 제품을 위한 다양한 인증</span>
                </>
              ) : (
                <>
                  CERTIFICATION For <br />
                  professional products
                </>
              )}
            </div>
          </div>

          <div className="certifi-content">
            {img.map((item, idx) => {
              return (
                <div key={idx} className="img-box">
                  <img src={item.img} alt="표창" srcSet={item.src} />
                  {item.title}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
};

export default CERTIFI;

const img = [
  {
    img: "/assets/about/img1.png",
    title: "EU-Type Examination Certificate",
    src: "/assets/about/img1@2x.png 2x, assets/about/img1@3x.png 3x",
  },

  {
    img: "/assets/about/img2.png",
    title: "EU-Type Examination Certificate",
    src: "/assets/about/img2@2x.png 2x, assets/about/img2@3x.png 3x",
  },

  {
    img: "/assets/about/img3.png",
    title: "EU-Type Examination Certificate",
    src: "/assets/about/img3@2x.png 2x, assets/about/img3@3x.png 3x",
  },

  {
    img: "/assets/about/img4.png",
    title: "EU-Type Examination Certificate",
    src: "/assets/about/img4@2x.png 2x, assets/about/img4@3x.png 3x",
  },

  {
    img: "/assets/about/img5.png",
    title: "EU-Type Examination Certificate",
    src: "/assets/about/img5@2x.png 2x, assets/about/img5@3x.png 3x",
  },

  {
    img: "/assets/about/img6.png",
    title: "EU-Type Examination Certificate",
    src: "/assets/about/img6@2x.png 2x, assets/about/img6@3x.png 3x",
  },

  {
    img: "/assets/about/img7.png",
    title: "EU-Type Examination Certificate",
    src: "/assets/about/img7@2x.png 2x, assets/about/img7@3x.png 3x",
  },

  {
    img: "/assets/about/img8.png",
    title: "EU-Type Examination Certificate",
    src: "/assets/about/img8@2x.png 2x, assets/about/img8@3x.png 3x",
  },
];
